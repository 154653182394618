<template>
	<el-dialog v-dialogDrag :title="formParam.id?'修改类型':'新增类型'" :visible.sync="visible" width="600px">
		<el-form label-width="100px" :model="formParam" ref="form" :rules="rules">
			<el-form-item label="类型名称" prop="typeName">
				<el-input maxLength="15" v-model="formParam.typeName"></el-input>
			</el-form-item>
			<!-- <el-form-item label="上级类型">
        <el-input ></el-input>
      </el-form-item> -->
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
			<el-button size="small" :loading="btnLoading" type="primary" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {},
				btnLoading: false,
				rules: {
					typeName: [{
						required: true,
						message: '请输入类型名称',
						trigger: 'blur'
					}],
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			value: {
				type: Object,
				default: () => {
					return {}
				},
			}
		},
		computed: {
			visible: {
				get() {
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {
			value(n, o) {
				if (this.value) {
					this.formParam = JSON.parse(JSON.stringify(this.value));
				}
			}
		},
		created() {},
		mounted() {},
		methods: {
			// 提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if(this.formParam.id){
							this.update()
						}else{
							this.save()
						}
					}
				});
			},
			// 新增
			async save() {
				this.btnLoading = true;
				let res = await this.$post('/platform-config/templatetype', this.formParam);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.$parent.getTree()
					this.$emit('update:show', false)
				}
			},
			// 修改
			async update() {
				this.btnLoading = true;
				let res = await this.$put('/platform-config/templatetype', this.formParam);
				this.btnLoading = false;
				if (res && res.code == 0) {
					this.$message.success("修改成功！");
					this.$parent.getTree()
					this.$emit('update:show', false)
				}
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}
</style>
